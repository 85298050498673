.wallet-adapter-button-trigger {
  background-color: #ffc000;
  height: 40px;
  border-radius: 44px;
  color: #101010;
  font-weight: 600;
}
.wallet-adapter-button-trigger:not([disabled]):hover {
  background-color: #8f6f0e;
}

.wallet-connected .wallet-adapter-button-trigger {
  background-color: #131313;
  border: 1px solid #2e2e2e;
  color: white;
}
.wallet-connected .wallet-adapter-button-trigger:not([disabled]):hover {
  background-color: #2c2c2c;
}